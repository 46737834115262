/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    div: "div",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "http://litox9.files.wordpress.com/2012/06/250px-openid_logo-svg.png"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 250px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 37.34177215189873%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABnElEQVQoz1WRTUhUYRSGn1Ey+4HUDCQshTaRue8HQaiUIoR+GCi0OsexH1oUDK0j0AhaRKtgoHETUusaaBMWEQ5zL0QLXTWUFkSLoEUUgX5v3Lm3YhYf5zvnwMN73pcoinJlieXyDXSeWxplcOVhkUcSAlYvtqNzICcnBxkEb/TJP505BEt3xHHMu/ln21/Xv039LPYvr5SLpVcffxyLa9V+JFYvbezSZAbIgP8gaW2Vs0XOur/AvrhWrbz89HskFForX+/lry0sfZh9+2Y+rzEOrk21jQbngJweOZ0yNsjZJqNLzlY5V+Tk5RySsTkBnopr1Rfvn9xJTnuqMwx/v7kPnWZH8NxQpmJcTkHGYRmF4JyVczk4QzIeyGiXMyBnN1EUEdeqMwuL9bu/rvdWlGdAxxvebZJxNDh9ciZknMxUTMo5IeeIjJ5glGTslzOSKE+B6dtTf3x751Jlls+lqy2aaPjVEYxBOd1yxrIzO+TskrNeRpuMXjl7ZXQmvjaAURS1JND63DSLz+dyX+6P/zc/qReaw2gKyJrD+gOPAva7v6UB2AAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Logo OpenID\"\n        title=\"250px-OpenID_logo.svg\"\n        src=\"/static/fcce38019e72ba1392de21890042dbb0/63868/250px-openid_logo-svg.png\"\n        srcset=\"/static/fcce38019e72ba1392de21890042dbb0/c26ae/250px-openid_logo-svg.png 158w,\n/static/fcce38019e72ba1392de21890042dbb0/63868/250px-openid_logo-svg.png 250w\"\n        sizes=\"(max-width: 250px) 100vw, 250px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "Hace poco me registré en un producto de mozilla, creo que era ", React.createElement(_components.a, {
    href: "http://openbadges.org/",
    title: "Open Badges"
  }, "Open Badges"), " y me molestó que sólo me dejaran registrarme con BrowserID. BrowserID es un sistema de identificación muy parecido a ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2010/02/22/openid/"
  }, "OpenID"), ", la principal diferencia es que BrowserID utiliza direcciones de correo electónico en lugar de URLs como forma de entrada."), "\n", React.createElement(_components.p, null, "Entiendo que siendo un producto de Mozilla sus herramientas lo utilicen, pero no comprendo limitarlo a esa forma de acceso cuando existe OpenID que tiene un cierto éxito entre la comunidad geek y bloguera."), "\n", React.createElement(_components.p, null, "Pero hay una razón especial por la que BrowserID no me convence. Cuando utilizamos BrowserID hacemos uso de una cuenta de correo, mientras que en OpenID usamos una dirección web, yo uso por ejemplo la de este blog y me va muy bien puesto que cuando comento se relaciona automáticamente el comentario con mi identidad en red, es decir mi blog."), "\n", React.createElement(_components.p, null, "BrowserID tiene un enfoque mas dedicado al usuario común, ese que no tiene blog y utiliza las “redes sociales”, pero que les hace pensar a la gente de Mozilla que esa gente va a preferir identificarse con su cuenta de correo a hacerlo con Facebook Connect, por ejemplo."), "\n", React.createElement(_components.p, null, "Creo que BrowserID no sale de la lógica industrial del consumidor, cuando ya estamos en plena era informacional donde el prosumidor es el rey y donde una de las formas más sencillas de ", React.createElement(_components.em, null, "prosumir"), " es el blog . OpenID defiende esta esencia bloguera y de la Web en general."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
